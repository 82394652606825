@import '~antd/dist/antd.less';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ant-layout {
    min-height: 100vh;
}

.ant-table-thead > tr > th {
    background-color: #fff;
}

@primary-color: #514adc;@body-background: #f7f6ff;@layout-body-background: #f7f6ff;