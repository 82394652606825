.ant-layout-sider {
    border-right: 1px solid #f0f0f0;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: none !important;
}

.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
    color: rgb(255 255 255) !important;
    background: #514adc !important;
}

.ant-layout-sider-zero-width-trigger {
    bottom: 64px !important;
    top: auto !important;
}

@primary-color: #514adc;@body-background: #f7f6ff;@layout-body-background: #f7f6ff;