.organisation {
    &__spinner {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 1em;
    }

    &__logo {
        padding: 1em;

        img {
            max-width: 100%;
            max-height: 150px;
        }
    }
}

@primary-color: #514adc;@body-background: #f7f6ff;@layout-body-background: #f7f6ff;