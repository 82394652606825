.register {
    &__left,
    &__right {
        padding: 4em;
    }

    &__left {
        background-color: #fff;
    }

    .ant-col.register__left {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ant-col.register__right {
        display: flex;
        min-height: 100vh;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 768px) {
        .ant-row {
            flex-direction: column;
        }

        .ant-col.register__left {
            min-height: auto;
            max-width: 100%;
        }

        .ant-col.register__right {
            min-height: 100vh;
            max-width: 100%;

            svg {
                width: 100%;
            }
        }
    }
}

@primary-color: #514adc;@body-background: #f7f6ff;@layout-body-background: #f7f6ff;